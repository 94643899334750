import * as React from 'react'
import Layout from '../components/layout'
import HeroHome from '../components/herohome'
import Partners from '../components/partners'
import { Link } from 'gatsby'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Software call center : Thelgo la soluzione definitiva" description="Software per Call Center con funzioni di chiamate automatiche, per aumentare le chiamate utili, scartando chiamate accupate, mute, non raggiungibili" />
      <HeroHome />

      <section className="px-6 py-12">
        <div className="container mx-auto">
          <div className="grid grid-col-1 lg:grid-cols-3 gap-6 lg:gap-12">
            <div>
              <div className="panel border-t-4 border-persian-green-500">
                <span className="text-lg font-bold text-persian-green-500 block mb-4">Chiamate Inbound</span>
                <p>Il software Thelgo insieme al centralino VOIspeed permette di gestire anche le chiamate inbound del call center, di identificare il chiamate e indicarti esattamente su quale numero hai ricevuto la chiamata; quindi di associare i numeri della terminazione telefonica a vari servizi e/o client.</p>
                <Link to="/caratteristiche/centralino-telefonico-cloud-evoluto-con-tecnologia-voispeed" title="centralino telefonico VOIspeed" className="text-persian-green-500 hover:text-persian-green-600 transition-all inline-flex items-center mt-3 hover:translate-x-2">Scopri di più <ChevronRightIcon className="w-5 h-5" /></Link>
              </div>
            </div>

            <div>
              <div className="panel border-t-4 border-persian-green-500">
              <span className="text-lg font-bold text-persian-green-500 block mb-4">Chiamate automatiche</span>
                <p>Grazie alla funzione chiamate automatiche (predictive dialing integrato con VOIspeed) il software Thelgo fornisce ininterrottamente nuove chiamate agli operatori creando nuovi contatti quando l’operatore sta ancora parlando al telefono con un cliente. Il sistema calcola la disponibilità ed il numero degli operatori, seleziona in previsione dei numeri di telefono e li assegna agli operatori che si liberano.</p>
                <Link to="/caratteristiche/centralino-telefonico-cloud-evoluto-con-tecnologia-voispeed" title="centralino telefonico VOIspeed" className="text-persian-green-500 hover:text-persian-green-600 transition-all inline-flex items-center mt-3 hover:translate-x-2">Scopri di più <ChevronRightIcon className="w-5 h-5" /></Link>
              </div>
            </div>

            <div>
              <div className="panel border-t-4 border-persian-green-500">
              <span className="text-lg font-bold text-persian-green-500 block mb-4">Semplicità di utilizzo</span>
                <p>Thelgo, software per call center è un potente strumento software molto utile per incrementare il numero dei propri clienti anche grazie alla semplicità del suo utilizzo. Si adatta in modo facile alle numerose esigenze aziendali. Il dialer si regola in modo automatico per massimizzare la produttività degli operatori call center.</p>
                <Link to="/caratteristiche/centralino-telefonico-cloud-evoluto-con-tecnologia-voispeed" title="centralino telefonico VOIspeed" className="text-persian-green-500 hover:text-persian-green-600 transition-all inline-flex items-center mt-3 hover:translate-x-2">Scopri di più <ChevronRightIcon className="w-5 h-5" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="px-6 py-12 bg-gray-50">
        <div className="container mx-auto">
          <div className="text-center mb-8">
            <span className="tag">Scenari di utilizzo</span>
            <h3 className="text-3xl text-gray-800">A che è rivolto Thelgo</h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
            <div>
              <div className="panel flex items-center flex-wrap text-center justify-center lg:flex-nowrap lg:text-right gap-6">
                <div>
                  <StaticImage src="../images/contact@4x.png" alt="Contact e Call Center" layout="fixed" height={200} placeholder="blurred" />
                </div>
                <div>
                  <h4>Call & Contact Center</h4>
                  <p>Vuoi gestire un Call Center o Contact Center? Thelgo è il software necessario per aprire un Call Center o Contact Center</p>
                  <Link to="/vuoi-gestire-un-call-center-o-contact-center" className="bg-persian-green-500 px-4 py-2 text-white uppercase inline-flex mt-3 rounded-md hover:bg-persian-green-600 transition-colors">Scopri di più</Link>
                </div>
              </div>
            </div>

            <div>
              <div className="panel flex items-center flex-wrap text-center justify-center lg:flex-nowrap lg:text-left gap-6">

                <div>
                  <h4>Divisioni Marketing & Customer Satisfaction</h4>
                  <p>Rendi produttiva la tua attività lavorativa con il CRM Marketing & Custome Satisfaction</p>
                  <Link to="/rendi-produttiva-la-tua-attivita-con-il-crm-marketing" className="bg-persian-green-500 px-4 py-2 text-white uppercase inline-flex mt-3 rounded-md hover:bg-persian-green-600 transition-colors">Scopri di più</Link>

                </div>
                <div>
                  <StaticImage src="../images/marketing@4x.png" alt="Contact e Call Center" layout="fixed" height={200} placeholder="blurred" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="px-6 py-12">
        <div className="container mx-auto">
          <div className="text-center mb-8">
            <span className="tag">Panoramica</span>
            <h3 className="text-3xl text-gray-800">Perché utilizzare Thelgo</h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
            <div className="flex flex-wrap text-center justify-center lg:flex-nowrap lg:text-left gap-6 panel-home">
              <div>
                <StaticImage src="../images/easy-large.png" layout="fixed" width={150} placeholder="blurred" alt="Semplicità Thelgo" />
              </div>
              <div>
                <h4>Semplice da utilizzare</h4>
                <p>Thelgo, il software per call center. è un potente strumento software molto utile per incrementare il numero dei propri clienti anche grazie alla semplicità del suo utilizzo. Si adatta in modo facile alle numerose esigenze aziendali e l’intuitiva interfaccia software non richiede una particolare assistenza dopo l’impostazione.</p>
              </div>
            </div>

            <div className="flex flex-wrap text-center justify-center lg:flex-nowrap lg:text-left gap-6 panel-home">
              <div>
                <StaticImage src="../images/agenda-large.png" layout="fixed" width={150} placeholder="blurred" alt="Semplicità Thelgo" />
              </div>
              <div>
                <h4>Agenda e agenti sempre aggiornati</h4>
                <p>Thelgo in grado di gestire in modo efficace e funzionale gli appuntamenti e la loro gestione, grazie alla sua agenda condivisa tra gli operatori del call center, gli agenti e i supervisori. Ogni singolo agente ha accesso alla propria utenza sia via web che tramite l’app appositamente realizzata per iOS o Android dalla quale può consultare i propri appuntamenti ed editarli.</p>
              </div>
            </div>

            <div className="flex flex-wrap text-center justify-center lg:flex-nowrap lg:text-left gap-6 panel-home">
              <div>
                <StaticImage src="../images/monitoraggio-large.png" layout="fixed" width={150} placeholder="blurred" alt="Semplicità Thelgo" />
              </div>
              <div>
                <h4>Monitoraggio costante</h4>
                <p>Tramite una semplice interfaccia web l’amministratore ha sempre tutto sotto controllo. E’ in grado di visualizzare le chiamate in corso e gli operatori connessi (in conversazione, in attesa di risposta, in pausa, ect..). I report dettagliati sono in grado di visualizzare immediatamente la resa delle campagne e degli operatori.</p>
              </div>
            </div>

            <div className="flex flex-wrap text-center justify-center lg:flex-nowrap lg:text-left gap-6 panel-home">
              <div>
                <StaticImage src="../images/quality-large.png" layout="fixed" width={150} placeholder="blurred" alt="Semplicità Thelgo" />
              </div>
              <div>
                <h4>Controllo qualità</h4>
                <p>Grazie al qualità check è possibile abilitare tutti o alcuni operatori a tale funzione. Questa funzione è utile perché è in grado di ricontattare i cliente che hanno un appuntamento programmato per chiederne conferma, la stessa funzione può essere utilizzata anche per contattare i clienti che hanno superato la fase 1 e che necessitano di essere ri-contattati per perfezionare il contratto o per altri motivi.</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-24">
            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
              {({ isVisible }) => (
                <>
                  <div className="text-center">
                    {isVisible ?
                      <CountUp end={1000} duration={2} prefix="Più di " className="text-3xl lg:text-5xl text-indigo-600 font-bold block" /> : null}
                    <span className="block uppercase text-gray-500">Operatori che utilizzano il software Thelgo</span>
                  </div>

                  <div className="text-center">
                    {isVisible ?
                      <CountUp end={2500} duration={2} suffix=" ore" className="text-3xl lg:text-5xl text-indigo-600 font-bold block" /> : null}
                    <span className="block uppercase text-gray-500">Di traffico giornaliero gestito</span>
                  </div>

                  <div className="text-center">
                    {isVisible ?
                      <CountUp end={30} duration={2} prefix="Oltre " suffix=" milioni" className="text-3xl lg:text-5xl text-indigo-600 font-bold block" /> : null}
                    <span className="block uppercase text-gray-500">Di chiamate gestite ogni anno</span>
                  </div>
                </>
              )}
            </VisibilitySensor>
          </div>
        </div>

        <div className="text-center mt-12">
          <Link to="/demo" className="button-lg bg-persian-green-500 inline-flex text-white hover:bg-persian-green-700 transition-colors">Richiedi una demo</Link>
        </div>
      </section>

      <Partners />
    </Layout>
  )
}

export default IndexPage